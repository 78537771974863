<template>
    <div class="row">
        <div class="col-md-12">
            <h3 class="text-muted mt-5">Obtener establecimientos</h3>
            <p>Para obtener el listado de tus plazas necesitas realizar una consulta método <code>POST</code> a la url <code>{{ url }}</code>, esto devolverá un listado de tus plazas con sus accesos.</p>

            <p>La respuesta exitosa contiene además la lista de accesos de dicho establecimiento, cada acceso tiene las propiedades <strong>name, id y color</strong> </p>            
            <img src="@/assets/imagenes/diagrama.png" alt="Diagrama entidad relación" id="diagrama">
            
            <h5 class="text-muted">Ejemplo</h5>
            <div class="row mb-3">
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon3">token</span>
                        </div>
                        <input type="text" class="form-control" aria-describedby="basic-addon3" v-model="token">
                    </div>
                    <loading-button @click.native="peticion" class="btn btn-primary" :loading="loading">Hacer petición</loading-button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h5 class="text-muted">Petición</h5>
                    <div class="terminal">
                        {{ curlPlazas }}
                    </div>
                </div>
                <div class="col-md-6">
                    <h5 class="text-muted">Respuesta</h5>
                    <json-viewer
                        :value="response"
                        boxed
                    ></json-viewer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import axios from 'axios'
import ButtonLoading from 'vue-loading-button'

export default {
    name: 'plazas',
    components: {
        'json-viewer': JsonViewer,
        'loading-button': ButtonLoading
    },
    data(){
        return {
            url: 'https://app.pogendata.com/api/data/plazas',
            token: '',
            loading: false,
            response: 'Realiza la petición para ver una respuesta'
        }
    },
    computed: {
        curlPlazas() {
            let token = ( this.token ) ? this.token : '{token}';
            let curl = "curl -XPOST -H 'Authorization: bearer "+token+"' '"+this.url+"'";
            return curl;
        }
    },
    methods: {
        peticion(){
            if( !this.token ){
                this.response = 'token requerido para la consulta';
                return false;
            }
            this.loading = true
            axios.post(this.url, {}, {
                headers: {
                    authorization: 'bearer '+this.token
                }
            }).then(response => {
                this.response = response.data
                this.loading = false
            }).catch(response => {
                this.response = response
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>
  .terminal{
    background-color: #000;
    padding: 10px;
    color: yellowgreen;
    overflow: auto;
  }
  #diagrama{
      width: 40vw;
  }  
</style>