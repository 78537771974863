<!-- Código html -->
<template>
    
  <div class="container">
    <h2>Documentación Pogen Data API</h2>
    <h3 class="text-muted mt-5">Introducción</h3>
    <p>Esta herramienta es utilizada principalmente para obtener información correspondiente al usuario como pueden ser los datos de sus plazas, por fecha y hora, obtener un listado de sus plazas con los accesos pertenecientes. Todas las respuestas serán devueltas en <code>JSON</code></p>
    <p>El tiempo de respuesta puede llegar a ser mas de <strong>50 segundos</strong> por lo que es importante considerarlo para las solicitudes</p>

    <login-component></login-component>

    <h3 class="text-muted mt-5">Consultas posteriores a la obtención del token</h3>
    <p>Cada consulta posterior a la obtención del token se tendrá que poner los campos correspondientes de cada método e incluir en los headers con nombre <code>Authorization</code> y valor <code>bearer {token}</code>.</p>
    
    <plazas-component></plazas-component>
    <datos-component></datos-component>
    
    <br>
  </div>

</template>

<!-- Código Vue -->
<script>
  import LoginComponent from '../components/data/Login.vue'
  import PlazasComponent from '../components/data/Plazas.vue'
  import DatosComponent from '../components/data/Datos.vue'
  export default {
    name: 'documentacion',
    components: {
      'login-component': LoginComponent,
      'plazas-component': PlazasComponent,
      'datos-component': DatosComponent
    }
  }
</script>

<!-- Estilo propio -->