<template>
    <div class="row mt-5">
        <div class="col-md-12">
            <h3 class="text-muted">Obtener datos</h3>
            <p>Con esta llamada puedes obtener los datos de las plazas pertenecientes en un rango de fechas con un <kbd>limite de 5,000</kbd> registros por consulta, se realiza una consulta por método <code>POST</code> a la url <code>{{ url }}</code>.</p>
            <p>Parámetros</p>
            <table class="table table-hover table-striped">
                <thead>
                    <th>Parámetro</th>
                    <th>Tipo</th>
                    <th>Descripción</th>
                </thead>
                <tbody>
                    <tr>
                        <td>plaza_id</td>
                        <td>INT</td>
                        <td>Si se presenta, obtiene solo los registros de esta plaza, en caso de no presentarse muestra todos los establecimientos pertenecientes</td>
                    </tr>
                    <tr>
                        <td>inicio</td>
                        <td>TEXT</td>
                        <td>Si se presenta, obtiene los registros desde esta fecha con formato YYYY-MM-DD HH:mm:SS</td>
                    </tr>
                    <tr>
                        <td>fin</td>
                        <td>TEXT</td>
                        <td>Si se presenta, obtiene los registros hasta esta fecha con formato YYYY-MM-DD HH:mm:SS</td>
                    </tr>
                    <tr>
                        <td>tipo</td>
                        <td>TEXT</td>
                        <td>Parámetro obligatorio en el que elegirás como obtener tus datos, por horas, días, semanas, meses o años.<br>Valores permitidos [hours, days, weeks, months, years]</td>
                    </tr>
                    <tr>
                        <td>pagina</td>
                        <td>INT</td>
                        <td>Parámetro que si se deja vacío comienza en la página 0 siendo ésta la principal</td>
                    </tr>
                    <tr>
                        <td>agrupar</td>
                        <td>BOOL</td>
                        <td>Si está presente agrupa la información por establecimiento sumando los datos de todos los accesos</td>
                    </tr>
                    <tr>
                        <td>campo_orden</td>
                        <td>TEXT</td>
                        <td>
                            Selecciona por que campo ordena la consulta, espera uno de los siguientes valores <code>fecha</code> ó <code>insercion</code><br>
                            Valor defecto: <i>fecha</i>
                        </td>
                    </tr>
                    <tr>
                        <td>orden</td>
                        <td>TEXT</td>
                        <td>
                            Ordena consulta en modo ascendiente o descendiente, espera uno de los siguientes valores <code>asc</code> ó <code>desc</code><br>
                            Valor defecto: <i>asc</i>
                        </td>
                    </tr>
                    <tr>
                        <td>salidas</td>
                        <td>INT</td>
                        <td>
                            Devuelve las salidas registradas según los parámetros establecidos.
                            <p>
                                <strong>
                                    * Solo si las salidas están habilitadas para el establecimiento en cuestión
                                </strong>
                            </p>
                        </td>
                    </tr>                    
                </tbody>
            </table>

            <div class="row mt-5">
                <div class="col-md-12">
                    <h5 class="text-muted">Ejemplo</h5>
                    
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">token</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" v-model="token">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">plaza_id</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" v-model="peticion.plaza_id">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">inicio</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" :placeholder="[[ fecha ]]" v-model="peticion.inicio">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">fin</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" :placeholder="[[ fecha ]]" v-model="peticion.fin">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">tipo</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="days" v-model="peticion.tipo">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">pagina</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="1" v-model="peticion.pagina">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">agrupar</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="1" v-model="peticion.agrupar">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">campo_orden</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="fecha" v-model="peticion.campo_orden">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">orden</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="asc" v-model="peticion.orden">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon3">salidas</span>
                                </div>
                                <input type="text" class="form-control" aria-describedby="basic-addon3" placeholder="0" v-model="peticion.salidas">
                            </div>
                            <loading-button @click.native="request" class="btn btn-primary" :loading="loading" :styled="style">Hacer petición</loading-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h5 class="text-muted">Petición</h5>
                            <div class="terminal">
                                {{ curl }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h5 class="text-muted">Respuesta</h5>
                            <json-viewer
                                :value="response"
                                boxed
                            ></json-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import JsonViewer from 'vue-json-viewer'
import _ from 'lodash'
import axios from 'axios'
import VueLoadingButton from 'vue-loading-button'

export default {
    name: 'datos',
    components: {
        'json-viewer': JsonViewer,
        'loading-button': VueLoadingButton
    },
    data(){
        return {
            url: 'https://app.pogendata.com/api/data/datos',
            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
            loading: false,
            style: false,
            token: '',
            peticion: {
                plaza_id: '',
                inicio: '',
                fin: '',
                tipo: '',
                pagina: '',
                agrupar: '',
                campo_orden: '',
                orden: '',
                salidas:''
            },
            response: 'Realiza la petición para ver una respuesta'
        }
    },
    computed: {
        curl() {
            let token = ( this.token ) ? this.token : '{token}';
            let request = []
            _.each(this.peticion, function(value, key){
                if( value ){
                    request.push(key+'='+value);
                }
            })
            let data = ''
            if( request.length ){
                data = '-d '
                data += request.join('&')
                data += ' '
            }
            let curl = "curl -XPOST "+data+"-H 'Authorization: bearer "+token+"' '"+this.url+"'";
            return curl;
        }
    },
    methods:{
        request(){
            if( !this.token ){
                this.response = 'token requierdo para hacer petición'
                return false
            }
            this.loading = true
            let request = {}
            _.each(this.peticion, function(value, key){
                if( value ){
                    request[key] = value
                }
            })

            axios.post(this.url, request, {
                headers: {
                    authorization: 'bearer '+this.token
                }
            }).then(response => {
                this.response = response.data
                this.loading = false
            }).catch(error => {
                this.response = error.response
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>
  .terminal{
    background-color: #000;
    padding: 10px;
    color: yellowgreen;
    overflow: auto;
  }
</style>